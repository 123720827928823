import React, {HTMLProps} from 'react'

export interface HeadingProps extends HTMLProps<HTMLHeadingElement> {
  dotsContainerClassName?: string
  outline?: boolean
}

export default function Heading({as = 'h2', className = '', outline = false, children, dotsContainerClassName = '', ...props}: HeadingProps) {
  const Component: any = as

  return (
    <Component
      className={`relative inline overflow-visible text-3xl md:text-4xl text-ci ${outline ? 'heading-outline' : ''} ${className}`}
      {...props}
    >
      <span className="relative z-10">{children}</span>
    </Component>
  )
}
